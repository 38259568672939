import React from 'react';
import './App.css';
import logo from './logo.png'; // Certifique-se de ter uma imagem logo.png na pasta src

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="app-logo" alt="logo" />
        <h1>Site em Manutenção</h1>
        <p>
          Olá! Somos a H2G Tecnologia e Automação LTDA, dedicados a trazer inovação para o mundo da automação. 
          Estamos em fase de atualização e logo estaremos de volta. Agradecemos a sua compreensão!
        </p>
      </header>
      
      <footer className="app-footer">
        <p>
          H2G Tecnologia e Automação LTDA<br />
          CNPJ: 53.124.437/0001-01<br />
          Rua Guilherme Scharf, 1900, Blumenau SC 89062-000<br />
          Fidelis - FUNDOS
        </p>
      </footer>
    </div>
  );
}

export default App;
